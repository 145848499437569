<template>
  <div>
    <h1>Users</h1>
    <div v-for="user in users" :key="user.id">
      <p>{{ user.email }}<span>{{ user.admin ? ' (Admin)' : '' }}</span></p>
    </div>
  </div>
</template>
<script>
import {GET_USERS} from "@/modules/admin/graph/queries";

export default {
  name: "AdminUser",
  data() {
    return {
      users: []
    }
  },

  apollo: {
    users: {
      query: GET_USERS
    }
  }
};
</script>