<template>
  <fragment>
    <div class="totalWrapper">
            <router-link :to="{ name: 'adminBusiness' }" class="total">
              <p>Businesses</p>
              <p>{{ businesses }}</p>
            </router-link>
            <div class="total">
              <p>Locations</p>
              <p>{{ locations }}</p>
            </div>
            <router-link :to="{ name: 'adminUsers' }" class="total">
              <p>Users</p>
              <p>{{ users }}</p>
            </router-link>
    </div>
    <div>
      <div v-show="selected === 'business'"><AdminBusiness /></div>
      <div v-show="selected === 'users'"><AdminUser /></div>
    </div>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { GET_ADMIN_DATA } from "@/modules/admin/graph/queries";
import AdminBusiness from "@/modules/admin/AdminBusiness";
import AdminUser from "@/modules/admin/AdminUser";

export default {
  name: "AdminDashboard",
  components: {
    AdminUser,
    AdminBusiness,
    Fragment,
  },
  data: function () {
    return {
      business: 0,
      locations: 0,
      users: 0,
      selected: "",
    };
  },
  methods: {
    refetch() {
      this.$apollo.queries.businesses.refetch();
    },
    closedStyle(status) {
      return status ? "businessClosed" : "";
    },
  },
  apollo: {
    businesses: {
      fetchPolicy: "cache-and-network",
      query: GET_ADMIN_DATA,
      variables() {
        return {
          name: "%%",
        };
      },
      result({ data }) {
        this.businesses = data?.businesses?.aggregate?.count
          ? data?.businesses?.aggregate?.count
          : 0;
        this.locations = data?.locations?.aggregate?.count
          ? data?.locations?.aggregate?.count
          : 0;
        this.users = data?.users?.aggregate?.count
          ? data?.users?.aggregate?.count
          : 0;
      },
    },
  },
};
</script>
<style scoped>
.one {
  width: 200px;
}

.two {
  width: 70px;
}

.three {
  width: 70px;
}

#tableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 0 10px;
  border-bottom: 1px solid #333333;
  color: #333333;
  font-size: 16px;
}

.tableRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
}

#table:nth-child(even) {
  background-color: #f3f3f3;
}

.row {
  display: flex;
  height: 35px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  text-decoration: none;
}

.row:hover {
  background-color: var(--powder-blue);
}

.businessClosed {
  color: darkred;
}

.total {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid var(--celadon-blue);
  border-radius: 20px;
  text-align: center;
  justify-content: center;
  max-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  margin: 15px;
}

.total p:last-child {
  font-size: 25px;
}

.totalWrapper {
  display: flex;
  justify-content: center;
}
</style>
